<template>
    <component 
        v-bind:is="getComponent(layer)" 
        :layer="layer" 
        :brandkit="brandkit" 
        :samples="samples"
        :onUpdate="onUpdate"
        :palette="palette"
        :fonts="fonts"/>
</template>

<script>
import listComponent from './components/list.vue'
    export default {
        props: {
            layer: Object,
            fonts: Array,
            mediaFolder: String,
            onUpdate: Function,
            inputName: String,
            brandkit: Object,
            samples: Object,
            palette: {
                type: Array,
                default: () => ([])
            },
            nightswatch: Boolean,
            is_studio: Boolean,
            version: Number,
        },
        data() {
            return {
                
            }
        },
        mounted() {
            
        },
        methods: {
            getComponent(layer) {
                switch(layer.config.component.type) {
                    case 'list':
                        return listComponent
                    default:
                        return listComponent
                }
            }
        }
    }
</script>
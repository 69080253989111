<template>
  <div style="padding: 12px;">
    <ul style="margin:0; padding:0;">
      <li v-for="(item, index) in listItems" :key="index" :style="{ fontSize: layer.fontSize * 0.9 + 'px' }">
        {{ item.text }}
      </li>
    </ul>
    
    <span ref="textSizer" :style="{ visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap' }"></span>
  </div>
</template>

<script>
export default {
  props: ['layer', 'animation_uid'],
  data() {
    return {
      
    }
  },
  computed: {
    listItems() {
      return this.layer.config.component.items || []
    }
  },

}
</script>
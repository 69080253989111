import _ from 'lodash'
import Layer from './model'

export const autoScaleFontSize = (layer) => {
  function measureText(text, fontSize, textareaWidth, leftPad = 0, rightPad = 0) {
    // Create a temporary div element to mimic the textarea
    const textSizer = document.createElement('div');
    textSizer.style.visibility = 'hidden';
    textSizer.style.position = 'absolute';
    textSizer.style.fontSize = `${fontSize}px`;
    textSizer.style.width = `${textareaWidth}px`;
    textSizer.style.whiteSpace = 'pre-wrap'; // Preserve whitespace and wrap text
    textSizer.style.wordWrap = 'break-word'; // Break words to fit within the width
    textSizer.style.paddingLeft = `${leftPad}px`;
    textSizer.style.paddingRight = `${rightPad}px`;
    textSizer.style.lineHeight = 'normal'; // Ensure line height is normal
    textSizer.textContent = text;

    // Append the div to the body
    document.body.appendChild(textSizer);

    // Measure the text
    const textHeight = textSizer.offsetHeight;

    // Remove the div from the body
    document.body.removeChild(textSizer);

    return textHeight;
  }

  function findOptimalFontSize(layer, containerWidth) {
    let minFontSize = 10;
    let maxFontSize = 999;
    let bestFontSize = minFontSize;

    while (minFontSize <= maxFontSize) {
      const midFontSize = Math.floor((minFontSize + maxFontSize) / 2);
      const textWidth = measureText(
        layer.get_text(),
        midFontSize,
        layer.get_text_width_constraint(),
        0,
        0
      );

      if (textWidth <= containerWidth) {
        bestFontSize = midFontSize;
        minFontSize = midFontSize + 1;
      } else {
        maxFontSize = midFontSize - 1;
      }
    }

    return bestFontSize * 1
  }

  const containerWidth = layer.width;
  const fontSize = findOptimalFontSize(layer, containerWidth);
  return fontSize
}

export const getLinkedLayers = (layers, layer) => {
  const linked_layers = layers.filter((l) => {
    const is_sibling = l.parent_id && layer.parent_id && l.parent_id === layer.parent_id
    const incoming_is_parent = l.parent_id && l.parent_id === layer.id
    const incoming_is_child = layer.parent_id && l.id === layer.parent_id
    return is_sibling || incoming_is_parent || incoming_is_child
  })

  // Add the layer itself if it is a parent
  if (!layer.parent_id) {
    linked_layers.push(layer)
  }

  return linked_layers
}

export const getTransformValue = (axis, layer) => {
  const transform = layer.config.transform || 'translate(0px, 0px)';
  const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/);
  if (match) {
    return Math.round(axis === 'x' ? parseFloat(match[1]) : parseFloat(match[2]));
  }
  return 0;
}

export const updateLinkedLayers = (layers, layer, variants = []) => {
  layers = layers.map((l) => new Layer({ ...l }))
  layer = new Layer({ ...layer })

  const linked_layers = getLinkedLayers(layers, layer)
  const origin_variant = variants.find((v) => v.id === layer.image_canvas_variant_id)

  const updated_linked_layers = linked_layers.map((l) => {
    const target_variant = variants.find((v) => v.id === l.image_canvas_variant_id)

    const propertyLinkMap = {
      color: Layer.LINK_TEXT_COLOR,
      background: Layer.LINK_BACKGROUND,
      align: Layer.LINK_ALIGNMENT,
      fontSize: Layer.LINK_FONT_SIZE
    };

    Layer.synchoronized_properties.forEach((prop) => {
      if (propertyLinkMap[prop]
        && (l.is_unlinked(propertyLinkMap[prop])
          || layer.is_unlinked(propertyLinkMap[prop]))) {
        // Add some code here if needed
      } else {
        l[prop] = layer[prop];
      }
    })
    const conf = _.cloneDeep(layer.config)

    // TODO
    // transform is not a property that should be copied
    // if we do want to copy it we should copy it to scale of its corresponding variant size
    // variant.size.display_dimensions[0] width
    // variant.size.display_dimensions[1] height
    // conf.transform contains strings like: rotate(0deg) translate(-221.3235294px, 529.0877647px)
    // delete conf.transform

    if (origin_variant.name !== target_variant.name
      && !layer.is_unlinked(Layer.LINK_POSITION_DIMENSIONS)
      && !l.is_unlinked(Layer.LINK_POSITION_DIMENSIONS)) {
      const originWidth = origin_variant.size.display_dimensions[0];
      const originHeight = origin_variant.size.display_dimensions[1];
      const targetWidth = target_variant.size.display_dimensions[0];
      const targetHeight = target_variant.size.display_dimensions[1];

      // Calculate aspect ratios
      const originAspectRatio = originWidth / originHeight;
      const targetAspectRatio = targetWidth / targetHeight;

      // Correct width/height scaling based on aspect ratio
      l.variable_tag = l.variable_tag || ''
      const scaleForTags = ['lifestyle']
      const scaleForTypes = ['background', 'image']
      const neverScaleRatioTags = ['logo', 'generic', 'product']
      let scaleToAspectRatio = false

      if (layer.is_linked(Layer.LINK_ASPECT_ACROSS_SIZES)) {
        scaleToAspectRatio = true
      }

      if (originAspectRatio !== targetAspectRatio
        && !neverScaleRatioTags.some((tag) => (layer.variable_tag ? layer.variable_tag.includes(tag) : false)) // eslint-disable-line
        && (scaleForTags.some((tag) => (layer.variable_tag ? layer.variable_tag.includes(tag) : false)) // eslint-disable-line
          || scaleForTypes.includes(layer.layer_type))) {
        scaleToAspectRatio = true
      }

      if (conf.transform && layer.rotated === false) {
        conf.transform = conf.transform.replace(/translate\(([^,]+),\s*([^)]+)\)/, (match, x, y) => {
          const layerWidth = layer.width;
          const layerHeight = layer.height;

          const layerWidthOfOriginSizeInPercentage = layerWidth / originWidth;
          const layerHeightOfOriginSizeInPercentage = layerHeight / originHeight;

          // Scale X and Y translation based on original size
          const offsetXPercent = parseFloat(x) / originWidth;
          const offsetYPercent = parseFloat(y) / originHeight;

          // Calculate new translation values based on target dimensions
          let newX = offsetXPercent * targetWidth;
          let newY = offsetYPercent * targetHeight;

          if (scaleToAspectRatio === false) {
            if (newX + layerWidth / 2 > (0.5 * targetWidth)) {
              newX += (layerWidthOfOriginSizeInPercentage * targetWidth - layerWidth);
            }

            if (newY + layerHeight / 2 > (0.5 * targetHeight)) {
              newY += (layerHeightOfOriginSizeInPercentage * targetHeight - layerHeight);
            }
          }

          // Return the new translate transform with calculated X and Y
          return `translate(${newX}px, ${newY}px)`;
        });
      }

      // Adjust layer dimensions
      if (l.width && l.height) {
        if (scaleToAspectRatio) {
          l.width = layer.width * (targetWidth / originWidth);
          l.height = layer.height * (targetHeight / originHeight);
        } else {
          l.width = layer.width;
          l.height = layer.height;
        }
      }
    } else {
      delete conf.transform;
    }

    if (l.is_unlinked(Layer.LINK_ADJUSTMENTS) || layer.is_unlinked(Layer.LINK_ADJUSTMENTS)) {
      delete conf.opacity
      delete conf.border_radius
    }

    if (l.is_unlinked(Layer.LINK_ALIGNMENT) || layer.is_unlinked(Layer.LINK_ALIGNMENT)) {
      delete conf.v_align
    }

    if (l.is_unlinked(Layer.LINK_SHADOWS) || layer.is_unlinked(Layer.LINK_SHADOWS)) {
      delete conf.dropshadows
    }

    if (l.is_unlinked(Layer.LINK_PADDING) || layer.is_unlinked(Layer.LINK_PADDING)) {
      delete conf.padding_top
      delete conf.padding_bottom
      delete conf.padding_left
      delete conf.padding_right
    }

    if (l.is_unlinked(Layer.LINK_HIGHLIGHTS) || layer.is_unlinked(Layer.LINK_HIGHLIGHTS)) {
      delete conf.highlights
    }

    if (l.is_unlinked(Layer.LINK_ANIMATIONS) || layer.is_unlinked(Layer.LINK_ANIMATIONS)) {
      delete conf.animations
      delete conf.animation
    }

    l.config = { ...l.config, ...conf }
    return new Layer({ ...l })
  })
  return { linked_layers, updated_linked_layers }
}

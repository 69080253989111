<template>
    <div class="form-group">
        <div class="form_group__detach">
            <div class="button__tab_group">
                <button type="button" class="button__toggle__light" 
                    @click.stop="e => toggle_pos_dim_font()">
                    <i v-if="unlinked_pos_dim_font" class="fa-regular fa-unlink" aria-hidden="true"></i>
                    <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
                    
                    <span v-if="unlinked_pos_dim_font" class="tooltip tooltip--top">Attach position & fontsize</span>
                    <span v-else class="tooltip tooltip--top">Detach position & fontsize</span>
                </button>

                <button type="button" class="button__toggle__light" 
                    @click.stop="e => toggle_maintain_aspect_across_sizes()">
                    <i v-if="unlinked_aspect_across_sizes" 
                        class="fa-solid fa-window-restore" aria-hidden="true"></i>
                    <i v-else class="fa-regular fa-window-restore" aria-hidden="true"></i>
                    <span v-if="unlinked_aspect_across_sizes" 
                        class="tooltip tooltip--top">Scale to aspect ratio</span>
                    <span v-else class="tooltip tooltip--top">Don't scale to aspect ratio</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LayerModel from './store/modules/layers/model'
export default {
    props: {
        activeLayer: Object,
        zoom: Number,
        animation_uid: String,
        onUpdate: Function
    },
    computed: {
        layer(){
            return this.activeLayer;
        },
        unlinked_aspect_across_sizes(){
            return this.activeLayer.unlinked_properties.includes(LayerModel.LINK_ASPECT_ACROSS_SIZES)
        },
        unlinked_pos_dim_font(){
            return this.activeLayer.unlinked_properties.includes(LayerModel.LINK_POSITION_DIMENSIONS) &&
                   this.activeLayer.unlinked_properties.includes(LayerModel.LINK_FONT_SIZE)
        }
    },
    mounted(){
      
    },
    watch:{
    },
    data(){
        return {
            LayerModel
        }
    },
    methods: {
        updateLayerConfig(key, value) {
            let layer = this.LayerModel.factory(this.activeLayer)
            this.onUpdate({ ...layer, [key]: value })
        },
        toggle_pos_dim_font(){
            if(this.unlinked_pos_dim_font){
                this.link_pos_dim_fontsize()
            }else{
                this.unlink_pos_dim_fontsize()
            }
        },
        toggle_maintain_aspect_across_sizes(){
            if(this.unlinked_aspect_across_sizes){
                this.link_aspect_across_sizes()
            }else{
                this.unlink_aspect_across_sizes()
            }
        },
        link_aspect_across_sizes(){
            let layer = this.LayerModel.factory(this.activeLayer)
            layer.link(LayerModel.LINK_ASPECT_ACROSS_SIZES)
            this.updateLayerConfig('unlinked_properties', layer.unlinked_properties)
        },
        unlink_aspect_across_sizes(){
            let layer = this.LayerModel.factory(this.activeLayer)
            layer.unlink(LayerModel.LINK_ASPECT_ACROSS_SIZES)
            this.updateLayerConfig('unlinked_properties', layer.unlinked_properties)
        },
        link_pos_dim_fontsize(){
            let layer = this.LayerModel.factory(this.activeLayer)
            layer.link(LayerModel.LINK_POSITION_DIMENSIONS)
            layer.link(LayerModel.LINK_FONT_SIZE)
            this.updateLayerConfig('unlinked_properties', layer.unlinked_properties)
        },
        unlink_pos_dim_fontsize(){
            let layer = this.LayerModel.factory(this.activeLayer)
            layer.unlink(LayerModel.LINK_POSITION_DIMENSIONS)
            layer.unlink(LayerModel.LINK_FONT_SIZE)
            this.updateLayerConfig('unlinked_properties', layer.unlinked_properties)
        },
    }
}
</script>
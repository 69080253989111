<template>
    <component 
        v-bind:is="getComponent(layer)" 
        :layer="layer" 
        :brandkit="brandkit" 
        :samples="samples"
        :on_error="on_error" 
        :active="active" 
        :animation_uid="animation_uid" 
        :disabled="disabled"/>
</template>

<script>
    import listComponent from './components/list.vue'
    export default {
        props: {
            layer: Object,
            brandkit: Object,
            samples: Object,
            on_error: Function,
            active: Boolean,
            animation_uid: String,
            disabled: Boolean,
        },
        data() {
            return {
                
            }
        },
        mounted() {
            
        },
        methods: {
            getComponent(layer) {
                switch(layer.config.component.type) {
                    case 'list':
                        return listComponent
                    default:
                        return listComponent
                }
            }
        }
    }
</script>
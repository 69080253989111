<template>
    <div class="layer-config__essentials">
      <TextConf 
        :layer="layer" 
        :is_studio="is_studio" 
        :version="version" 
        :fonts="fonts"
        :onUpdate="onUpdate"
        :inputName="inputName" 
        :show_wrap="false" 
        :palette="palette" 
        :nightswatch="nightswatch" 
        :show_input="false" />
    </div>
</template>

<script>
import TextConf from '../text.vue'
export default  {
  props: ['layer', 'fonts', 'mediaFolder', 'onUpdate', 'inputName', 'palette', 'nightswatch', 'is_studio', 'version'],
  components: { TextConf },
  data(){
    return {

    }
  }
}
</script>